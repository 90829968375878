















































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import PopoverInput from '@/components/popover-input.vue'
import {
    apiSeckillDel,
    apiSeckillOpen,
    apiSeckillStop
} from '@/api/marketing/seckill'

@Component({
    components: {
        LsDialog,
        LsPagination,
        PopoverInput
    }
})
export default class SeckillPane extends Vue {
    $refs!: { paneTable: any }
    @Prop() value: any
    @Prop() pager!: any

    // 删除活动
    handleDelete(ids: number) {
        apiSeckillDel({
            id: ids
        }).then(() => {
            this.$emit('refresh')
        })
    }

    // 开始活动
    handleStart(ids: number) {
        apiSeckillOpen({
            id: ids
        }).then(() => {
            this.$emit('refresh')
        })
    }

    // 结束活动
    handleStop(ids: number) {
        apiSeckillStop({
            id: ids
        }).then(() => {
            this.$emit('refresh')
        })
    }
}
