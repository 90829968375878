

















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import SeckillPane from '@/components/marketing/seckill-pane.vue'
import { RequestPaging } from '@/utils/util'
import { apiSeckillLists } from '@/api/marketing/seckill'
import DatePicker from '@/components/date-picker.vue'
import ExportData from '@/components/export-data/index.vue'
import { SeckillType } from '@/utils/type'

@Component({
    components: {
        DatePicker,
        ExportData,
        SeckillPane
    }
})
export default class SeckillLists extends Vue {
    tabs = [
        {
            label: '全部',
            name: SeckillType[0]
        },
        {
            label: '未开始',
            name: SeckillType[1]
        },
        {
            label: '进行中',
            name: SeckillType[2]
        },
        {
            label: '已结束',
            name: SeckillType[3]
        }
    ]

    queryObj = {
        activity: '',
        end_time: '',
        start_time: '',
        goods: ''
    }
    lists = []
    tabCount = {
        all: 0, //全部
        not: 0, //未开始
        conduct: 0, //进行中
        end: 0 //已结束
    }
    pager = new RequestPaging()
    activeName: any = 'all'
    apiSeckillLists = apiSeckillLists
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager
            .request({
                callback: apiSeckillLists,
                params: {
                    status: SeckillType[this.activeName],
                    ...this.queryObj
                }
            })
            .then(res => {
                this.tabCount = res?.extend
            })
    }
    resetQueryObj() {
        Object.keys(this.queryObj).map(key => {
            this.$set(this.queryObj, key, '')
        })
        this.getList()
    }
    created() {
        this.getList()
    }
}
